import React, { useRef, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
	attachmentsSelector,
	lessonMoveStatuses,
	postSelector,
	sendLessonStatus,
} from "../../store/reducers/lesson"
import Comments from "../Comments/Comments"
import EditorView from "../UIKit/editor/view/EditorView"
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import Attachments from "../attachments/Attachments"
import styles from "./lesson.module.scss"
import Typography from "../UIKit/base/Typography"

export default function Lesson() {
	const post = useSelector(postSelector)
	const attachments = useSelector(attachmentsSelector)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const lessonRef = useRef()

	const isDesc = useMemo(() => {
		const regex = /(<([^>]+)>)/gi
		const descWithoutTags =
			post?.description && post?.description.replace(regex, "")
		return !!descWithoutTags
	}, [post?.description])

	const onScroll = () => {
		if (lessonRef.current) {
			const bottom = lessonRef.current?.getBoundingClientRect()?.bottom

			const scrolledOtEnd = bottom <= window.innerHeight
			const longText =
				post?.type === "lesson" &&
				!post?.media &&
				lessonRef.current?.clientHeight &&
				lessonRef.current?.clientHeight >= window.innerHeight

			if (
				scrolledOtEnd &&
				longText &&
				post?.status !== lessonMoveStatuses.SEND &&
				post?.status !== lessonMoveStatuses.COMPLETED
			) {
				window.removeEventListener("scroll", onScroll)
				dispatch(sendLessonStatus(lessonMoveStatuses.COMPLETED))
			}
		}
	}

	useEffect(() => {
		const shortText =
			post?.type === "lesson" &&
			!post?.media &&
			post &&
			post?.status !== lessonMoveStatuses.SEND &&
			post?.status !== lessonMoveStatuses.COMPLETED &&
			((lessonRef.current?.clientHeight &&
				lessonRef.current?.clientHeight < window.innerHeight) ||
				!isDesc)

		if (shortText) {
			dispatch(sendLessonStatus(lessonMoveStatuses.COMPLETED))
		}
	}, [lessonRef.current?.clientHeight, post?.media, dispatch, post, isDesc])

	useEffect(() => {
		window.addEventListener("scroll", onScroll)

		return () => {
			window.removeEventListener("scroll", onScroll)
		}
	}, [])

	function handleProgress(e) {
		if (post.status === lessonMoveStatuses.COMPLETED) {
			return
		}
		const percent = Math.abs((e.target.currentTime / e.target.duration) * 100)
		if (
			percent >= 1 &&
			percent < 80 &&
			post.status !== lessonMoveStatuses.SEND &&
			post.status !== lessonMoveStatuses.IN_PROGRESS
		) {
			dispatch(sendLessonStatus(lessonMoveStatuses.IN_PROGRESS))
		}
		if (
			percent >= 80 &&
			post.status !== lessonMoveStatuses.SEND &&
			post.status !== lessonMoveStatuses.COMPLETED
		) {
			dispatch(sendLessonStatus(lessonMoveStatuses.COMPLETED))
		}
	}

	return (
		<>
			{(!!post?.media || isDesc) && (
				<div ref={lessonRef} className={styles.card}>
					<div className={styles.card__wrapper}>
						{post?.media && (
							<div className={styles.video}>
								<VideoPlayer
									handleProgress={handleProgress}
									url={post?.media?.url}
								/>
							</div>
						)}
						{isDesc && (
							<EditorView content={post?.description} fontVariant="medium" />
						)}
					</div>
					{attachments?.length ? (
						<div className={styles.card__wrapper}>
							<Typography className={styles.card__title}>
								{t("additional_materials")}
							</Typography>
							<div className={styles.attachments__wrapper}>
								<Attachments
									attachments={attachments}
									className={styles.attachments}
									titleClassName={styles.attachments__title}
									course
								/>
							</div>
						</div>
					) : null}
				</div>
			)}
			<div className={styles.comments__wrapper}>
				<Typography fontVariant="semiBold" className={styles.comments__title}>
					{t("comments")}
				</Typography>
				<Comments />
			</div>
		</>
	)
}
